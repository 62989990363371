import Service from "@/js/service.js"

const service = new Service(`${process.env.VUE_APP_FEATURE_STORE_SERVICE}/feature-store`)

const featureStoreService = {
  getMenu() {
    return new Promise((resolve, reject) => {
      service.getRequest(2, "menu", null, resolve, reject)
    })
  },
}

export default featureStoreService

