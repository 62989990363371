<script setup>
import {inject} from 'vue';
import SystemBar from '@/components/specific/menu/SystemBar.vue';
import AppBar from '@/components/specific/menu/AppBar.vue';

const IS_AUTH_PROVIDE = 'IS_AUTH';

const { isAuth } = inject(IS_AUTH_PROVIDE);
</script>

<template>
  <div>
    <SystemBar v-if="isAuth"/>
    <AppBar/>
  </div>
</template>

<style scoped>

</style>