<script>
import {defineComponent} from 'vue';
import {useStore} from '@/store';
import MenuLinkList from '@/components/specific/menu/MenuLinkList.vue';
import RecentlyCompletedTestsMenuPreview from '@/components/specific/menu/RecentlyCompletedTestsMenuPreview.vue';

export default defineComponent({
  name: 'TestingMenu',
  components: {RecentlyCompletedTestsMenuPreview, MenuLinkList},
  setup() {
    const store = useStore();

    let testingLinks = [
      {
        text: 'Configure and Run Tests',
        path: '/configure-and-run-tests',
      },
      {
        text: 'Schedule Tests',
        path: '/schedule-tests',
      },
      {
        type: 'divider',
      },
      {
        text: 'Test Status',
        path: '/test-status',
      },
      {
        text: 'Explorer Tests',
        path: '/explorer-tests',
      },
      {
        text: 'Currently Running Tests',
        path: '/currently-running-tests',
      },
      {
        text: 'Previous Tests',
        path: '/previous-tests',
      },
      {
        type: 'divider',
      },
      {
        text: 'Received SMS',
        path: '/received-sms',
      },
      {
        text: 'Received Messages (Kafka)',
        path: '/received-messages-kafka',
      },
      {
        text: 'Received Messages (CDR)',
        path: '/received-messages-cdr',
      },
      {
        text: 'Received Packets',
        path: '/received-packets',
      },
    ];

    if (store.state.organization.name === 'Vodafone GmbH' || store.state.organization.name === 'Deutsche GigaNetz') {
      const previousTestsIndex = testingLinks.findIndex(link => link.path === '/previous-tests');
      testingLinks.splice(previousTestsIndex, 1);
    }

    if (!store.state.permissions.superAdmin && ![1, 2].includes(store.state.permissions.orgaRole)) {
      testingLinks = testingLinks.filter(link => ![
        '/received-sms',
        '/received-messages-kafka',
        '/received-messages-cdr',
        '/received-packets',
      ].includes(link.path));
      if (testingLinks[testingLinks.length - 1].type === 'divider') {
        testingLinks.pop();
      }
    }

    return {
      testingLinks,
    };
  },
});
</script>

<template>
  <div class="testing-menu">
    <div class="testing-menu__menu-links-box">
      <MenuLinkList
        title="Testing"
        basePath="/testing"
        :links="testingLinks"
        class="testing-menu__menu-links"
      />
    </div>

    <RecentlyCompletedTestsMenuPreview class="testing-menu__recently-completed-tests" />
  </div>
</template>

<style lang="scss">
$menu-width: 720px;
$menu-links-box-width: 250px;
$menu-links-component-width: 180px;
$recently-completed-tests-width: 430px;


.testing-menu {
  display: flex;
  width: $menu-width;
  gap: 20px;
  padding: 5px 15px 30px 15px;

  &__menu-links-box {
    max-width: $menu-links-box-width;
    width: 100%;
  }

  &__menu-links {
    max-width: $menu-links-component-width;
    width: 100%;
  }

  &__recently-completed-tests {
    max-width: $recently-completed-tests-width;
    width: 100%;
  }
}
</style>
