<template>
  <div v-if="value" :data-help="computedHelp" class="x-dialog">
    <v-dialog
        v-if="!loading"
        :height="height"
        :persistent="persistent"
        :value="value"
        :width="width"
        :fullscreen="fullscreen"
        :content-class="contentClass"
        @input="$emit('input', $event)"
        @click:outside="$emit('click:outside', $event)">
      <v-card :style="height ? `height: ${height}px;` : ''">
        <div v-if="this.$slots['dialog-top'] || title" ref="dialogTop" class="dialog-top">
          <div v-if="!this.$slots['dialog-top']" class="dialog-top-bar white--text">
            <div v-if="title" class="dialog-title" v-text="title"/>
            <div class="corner-buttons">
              <x-btn
                  v-for="(cornerButton, i) in computedCornerButtons"
                  :key="i"
                  :icon="cornerButton.icon"
                  text-color="white"
                  @click="cornerButton.click"/>
            </div>
          </div>
          <slot name="dialog-top"/>
        </div>
        <div
            ref="dialogContent"
            :class="!unpadded ? `dialog-content ${$slots['dialog-bottom'] || buttons ? '' : 'dialog-content-bottom-padding'}` : ''"
            :style="computedHeight ? `height: ${computedHeight}px; overflow-y: ${alwaysShowScrollbar ? 'scroll' : 'auto'};` : ''">
          <div v-if="text" v-text="text"/>
          <slot name="dialog-content" :element="$refs.dialogContent" :handle-scroll-request="handleScrollRequest"/>
        </div>
        <div v-if="$slots['dialog-bottom'] || buttons" ref="dialogBottom" class="dialog-bottom">
          <v-btn
              v-for="button of buttons"
              :key="button.name"
              :color="button.color"
              min-width="130"
              min-height="40"
              :disabled="typeof button.disabled === 'function' ? button.disabled() : button.disabled"
              :style="`color: ${button.textColor}`"
              @click="button.click">
            <v-icon v-if="button.icon" dark left>{{ button.icon }}</v-icon>
            {{ button.text }}
          </v-btn>

          <slot name="dialog-bottom"/>
        </div>
      </v-card>
    </v-dialog>

    <LoadingDialog v-if="loading" :value="loading"/>

    <HelpButton :help="computedHelp"/>
  </div>
</template>

<script>
import XBtn from '@/components/basic/XBtn';
import LoadingDialog from '@/components/basic/LoadingDialog';
import HelpButton from '@/components/basic/HelpButton.vue';

export default {
  name: 'XDialog',
  components: {
    LoadingDialog,
    XBtn,
    HelpButton,
  },
  props: {
    value: Boolean,
    title: String,
    text: String,
    buttons: Array,
    cornerButtons: Array,
    width: {
      type: [Number, String],
      default: 500,
    },
    height: {
      Number,
      String,
    },
    contentHeight: {
      Number,
      String,
    },
    persistent: Boolean,
    unpadded: Boolean,
    loading: Boolean,
    id: String,
    maximizable: Boolean,
    alwaysShowScrollbar: Boolean,
    help: String,
    contentClass: String,
  },
  data() {
    return {
      fullscreen: false,
      dataHeight: 0,
    };
  },
  mounted() {
    let dataHeight = this.height;
    if (this.$refs.dialogTop) dataHeight -= this.$refs.dialogTop.clientHeight;
    if (this.$refs.dialogBottom) dataHeight -= this.$refs.dialogBottom.clientHeight;
    this.dataHeight = dataHeight;
  },
  computed: {
    computedCornerButtons() {
      const cornerButtons = [];
      if (this.cornerButtons) cornerButtons.push(...this.cornerButtons);
      if (this.maximizable) {
        cornerButtons.push(
            {
              icon: this.fullscreen ? 'mdi-window-restore' : 'mdi-window-maximize',
              click: this.toggleFullscreen,
            },
        );
      }
      cornerButtons.push(
          {
            icon: 'mdi-close',
            click: this.closeDialog,
          },
      );
      return cornerButtons;
    },
    computedHeight() {
      if (this.contentHeight) return this.contentHeight;
      return this.dataHeight;
    },
    computedHelp() {
      if (this.help) return this.help;
      return this.id;
    },
  },
  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },
    closeDialog() {
      this.$emit('input', false);
    },
    handleScrollRequest(value) {
      this.$nextTick(() => {
        if (this.$refs.dialogContent) {
          this.$refs.dialogContent.scrollTop = value;
        }
      });
    },
  },
};
</script>

<style scoped>
.dialog-top {
  font-size: 24px;
  font-weight: 400;
}

.dialog-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 400;
  background-color: var(--v-primary-base);
  height: 68px;
}

.dialog-title {
  padding: 10px 20px;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: .0125em;
  line-height: 2rem;
}

.corner-buttons {
  margin-right: 10px;
  display: flex;
}

.dialog-content {
  padding: 20px 20px 0 20px;
}

.dialog-content-bottom-padding {
  padding-bottom: 20px;
}

.dialog-bottom {
  display: flex;
  gap: 10px;
  padding: 20px;
}

.button {
  flex: 1 1 50%;
}

.x-dialog {
  display: flex;
}

.x-dialog >>> .help-button {
  margin-top: 2px;
}
</style>
