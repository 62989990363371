<script>
import {defineComponent, ref} from 'vue';

export default defineComponent({
  name: 'MenuButton',
  props: {
    text: String,
  },
  setup() {
    const menuOpened = ref(false);

    const closeMenu = () => {
      menuOpened.value = false;
    };

    return {
      menuOpened,
      closeMenu,
    };
  },
});
</script>

<template>
  <div>
    <v-menu v-model="menuOpened" open-on-hover offset-y close-delay="200" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" elevation="0" v-bind="attrs" v-on="on">
          {{ text }}
        </v-btn>
      </template>

      <v-card>
        <slot :close-menu="closeMenu"/>
      </v-card>
    </v-menu>
  </div>
</template>

