export const deepCopy = (source, target) => {
  if (typeof target === "undefined") target = getDefaultPrimitiveValue(source)
  if (!source) {
    target = source
    return
  }

  if (typeof source !== "object") {
    target = source
    return target
  }
  if (Array.isArray(source) && !Array.isArray(target)) {
    console.error("Source and target not compatible.\nSource: array\nTarget: object.")
    return
  }
  if (!Array.isArray(source) && Array.isArray(target)) {
    console.error("Source and target not compatible.\nSource: object\nTarget: array.")
  }

  if (Array.isArray(source)) {
    for (let i = 0; i < source.length; i++) {
      if (typeof source[i] === "object") {
        if (!(source[i] instanceof Date)) target.push(deepCopy(source[i]))
        else target.push(new Date(source[i]))
      } else {
        target.push(source[i])
      }
    }
  } else {
    for (let key in source) {
      if (typeof source[key] === "object") {
        if (!source[key]) target[key] = source[key]
        else if (!target[key]) target[key] = getDefaultPrimitiveValue(source[key])
        if (!(source[key] instanceof Date)) deepCopy(source[key], target[key])
        else target[key] = new Date(source[key])
      } else if (typeof source[key] === "function") {
        target[key] = source[key].bind(target)
      } else {
        target[key] = source[key]
      }
    }
  }

  return target
}
export const deepEquals = (a, b) => {
  const ok = Object.keys,
    ta = typeof a,
    tb = typeof b
  return a && b && ta === "object" && ta === tb
    ? ok(a).length === ok(b).length && ok(a).every((key) => deepEquals(a[key], b[key]))
    : a === b
}
export const deepClear = (target) => {
  if (typeof target !== "object") {
    console.error("Only arrays and objects can be deep-cleared.")
    return
  }

  if (Array.isArray(target)) {
    for (let i = 0; i < target.length; i++) {
      if (typeof target[i] === "object") {
        this.deepClear(target[i])
      } else {
        target[i] = getDefaultPrimitiveValue(target[i])
      }
    }
  } else {
    for (let key in target) {
      if (typeof target[key] === "object") {
        this.deepClear(target[key])
      } else {
        target[key] = getDefaultPrimitiveValue(target[key])
      }
    }
  }

  return target
}
export const deepReplace = (data, searchValue, replaceValue, caseSensitive) => {
  if (typeof data === "undefined" || typeof data === "number") return data

  if (typeof data === "string") {
    return data.replace(searchValue, replaceValue)
  } else if (typeof data !== "object") {
    return
  }

  const stack = []
  stack.push(data)

  while (stack.length) {
    const current = stack.pop()
    if (Array.isArray(current)) {
      for (let i = 0; i < current.length; i++) {
        if (typeof current[i] === "object") {
          stack.push(current[i])
        } else if (typeof current[i] === "string") {
          if (!caseSensitive) current[i] = current[i].replace(searchValue, replaceValue)
          else {
            const startIndex = current[i].toLowerCase().indexOf(searchValue.toLowerCase())
            if (startIndex >= 0) {
              current[i] =
                current[i].substring(0, startIndex) +
                replaceValue +
                current[i].substring(startIndex + searchValue.length)
            }
          }
        }
      }
    } else {
      for (let key in current) {
        if (typeof current[key] === "object") {
          stack.push(current[key])
        } else if (typeof current[key] === "string") {
          if (!caseSensitive) current[key] = current[key].replace(searchValue, replaceValue)
          else {
            const startIndex = current[key].toLowerCase().indexOf(searchValue.toLowerCase())
            if (startIndex >= 0) {
              current[key] =
                current[key].substring(0, startIndex) +
                replaceValue +
                current[key].substring(startIndex + searchValue.length)
            }
          }
        }

        if (!caseSensitive) {
          const startIndex = key.indexOf(searchValue)
          if (startIndex >= 0) {
            delete Object.assign(current, {
              [key.replace(searchValue, replaceValue)]: current[key],
            })[key]
          }
        } else {
          const startIndex = key.toLowerCase().indexOf(searchValue.toLowerCase())
          if (startIndex >= 0) {
            delete Object.assign(current, {
              [key.substring(0, startIndex) +
              replaceValue +
              key.substring(startIndex + searchValue.length)]: current[key],
            })[key]
          }
        }
      }
    }
  }

  return data
}
export const getDefaultPrimitiveValue = (value, defaultObjectValue = {}) => {
  switch (typeof value) {
    case "undefined":
      return undefined
    case "object":
      if (Array.isArray(value)) {
        return []
      }
      return defaultObjectValue
    case "boolean":
      return false
    case "number":
      return 0
    case "string":
      return ""
  }
  return undefined
}
export const getRequiredRule = () => {
  return (v) => !!v || v === 0 || getRequiredMessage()
}
export const getRequiredMessage = () => {
  return "This field is required."
}
export const setDefaultValues = (target, defaultValue) => {
  let changed = false
  if (
    target === null &&
    defaultValue &&
    typeof defaultValue === "object" &&
    !Array.isArray(defaultValue)
  ) {
    target = {}
    changed = true
  }
  if (defaultValue) {
    if (typeof defaultValue === "object") {
      if (!Array.isArray(defaultValue)) {
        for (const [key, value] of Object.entries(defaultValue)) {
          if (typeof value === "object" && value !== null) {
            if (!target[key]) target[key] = getDefaultPrimitiveValue(value)
            const newChanged = setDefaultValues(target[key], value)
            changed = changed ? changed : newChanged
          } else if ((target[key] === undefined || target[key] === null) && target[key] !== value) {
            target[key] = value
            changed = true
          }
        }
      } else {
        for (let i = 0; i < defaultValue.length; i++) {
          if (typeof defaultValue[i] === "object") {
            changed = changed ? changed : setDefaultValues(target[i], defaultValue[i])
          } else {
            target[i] = defaultValue[i]
            changed = true
          }
        }
      }
    }
  } else {
    if (!Array.isArray(target)) {
      for (const [key, value] of Object.entries(target)) {
        if (typeof value === "object" && value !== null) {
          if (!Array.isArray(value)) {
            changed = setDefaultValues(target[key])
          } else {
            target[key] = []
            changed = true
          }
        } else {
          target[key] = getDefaultPrimitiveValue(value)
          if (target[key] !== value) changed = true
        }
      }
    }
  }
  return changed
}
export const parseBoolean = (value) => {
  if (typeof value === "boolean") return value
  if (typeof value === "string") {
    value = value.toLowerCase()
    return value === "true" || value === "1"
  }
  if (typeof value === "number") return value !== 0
  if (value === undefined || value === null) return 0
}
export const nullEmptyObjects = (value) => {
  if (typeof value === "object") {
    if (!value) {
      return null
    } else if (!Array.isArray(value)) {
      const entries = Object.entries(value)
      if (!entries.length) return null
      for (const [k, v] of entries) {
        value[k] = nullEmptyObjects(v)
      }
    } else {
      for (let i = 0; i < value.length; i++) {
        value[i] = nullEmptyObjects(value[i])
      }
    }
  }
  return value
}
export const objectToArray = (object, keyName, valueName = "value") => {
  const array = []
  for (const [key, value] of Object.entries(object)) {
    const newObject = {}
    newObject[keyName] = key
    newObject[valueName] = value
    array.push(newObject)
  }
  return array
}
export const arrayToObject = (array, keyName, valueName = "value") => {
  const object = {}
  for (const item of array) {
    object[item[keyName]] = item[valueName]
  }
  return object
}
export const dateToIsoDateString = (d) => {
  if (!d) return null

  const year = d.getFullYear().toString().padStart(4, "0")
  const month = (d.getMonth() + 1).toString().padStart(2, "0")
  const day = d.getDate().toString().padStart(2, "0")

  return `${year}-${month}-${day}`
}
export const dateToIsoDateTimeString = (d) => {
  if (!d) return null

  const year = d.getFullYear().toString().padStart(4, "0")
  const month = (d.getMonth() + 1).toString().padStart(2, "0")
  const day = d.getDate().toString().padStart(2, "0")
  const hour = d.getHours().toString().padStart(2, "0")
  const minute = d.getMinutes().toString().padStart(2, "0")
  const second = d.getSeconds().toString().padStart(2, "0")

  return `${year}-${month}-${day}-${hour}-${minute}-${second}`
}
export const dateToCondensedIsoDateTimeString = (d) => {
  if (!d) return null
  return dateToIsoDateTimeString(d).replace(/-/g, "")
}
export const isoDateStringToDate = (s) => {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(s)) return null

  const year = parseInt(s.substring(0, 4))
  const month = parseInt(s.substring(5, 7)) - 1
  const day = parseInt(s.substring(8, 10))

  const result = new Date()
  result.setYear(year)
  result.setMonth(month)
  result.setDate(day)
  result.setHours(0)
  result.setMinutes(0)
  result.setSeconds(0)
  result.setMilliseconds(0)

  return result
}
export const isoDateTimeStringToDateTimeString = (s) => {
  return dateToDateTimeString(new Date(s))
}
export const dateToDateTimeString = (d, seconds, milliseconds) => {
  if (!d) return ""

  const year = d.getFullYear().toString().padStart(4, "0")
  const month = (d.getMonth() + 1).toString().padStart(2, "0")
  const day = d.getDate().toString().padStart(2, "0")
  const hour = d.getHours().toString().padStart(2, "0")
  const minute = d.getMinutes().toString().padStart(2, "0")
  const second = d.getSeconds().toString().padStart(2, "0")
  const millisecond = d.getMilliseconds().toString().padStart(3, 0)

  const date = `${year}-${month}-${day}`
  let time = `${hour}:${minute}`

  if (seconds || milliseconds) time += `:${second}`
  if (milliseconds) time += `.${millisecond}`

  return `${date} ${time}`
}
export const unixToDateTimeString = (unix) => {
  if (!unix) return ""
  return dateToDateTimeString(new Date(unix * 1000))
}
export const dateTimeToDateTimeStringMilliseconds = (dateTime) => {
  if (!dateTime) return ""
  return dateToDateTimeString(new Date(dateTime), true, true)
}
export const dateTimeStringToDate = (s) => {
  if (!/^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/.test(s)) return null

  const year = parseInt(s.substring(0, 4))
  const month = parseInt(s.substring(5, 7)) - 1
  const day = parseInt(s.substring(8, 10))
  const hour = parseInt(s.substring(11, 13))
  const minute = parseInt(s.substring(14, 16))

  const result = new Date()
  result.setYear(year)
  result.setMonth(month)
  result.setDate(day)
  result.setHours(hour)
  result.setMinutes(minute)
  result.setSeconds(0)
  result.setMilliseconds(0)

  return result
}

export const secondsToDuration = (seconds) => {
  let duration = ""
  if (seconds >= 86400) duration += `${parseInt(seconds / 86400)}d`
  if (seconds % 86400 >= 3600) duration += `${parseInt((seconds % 86400) / 3600)}h`
  if (seconds % 3600 >= 60) duration += `${parseInt((seconds % 3600) / 60)}m`
  if (seconds % 60 > 0 || seconds / 60 < 1) duration += `${parseInt(seconds % 60)}s`
  return duration
}

export const secondsToDuration2 = (_sec, { useColonFormat = false } = {}) => {
  let duration = ""
  const seconds = parseInt(_sec, 10)
  let days = 0

  if (seconds >= 86400) {
    days = Math.floor(seconds / 86400)
  }

  if (useColonFormat) {
    const hours = Math.floor((seconds % 86400) / 3600) + days * 24
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    if (hours > 0 || duration !== "") {
      duration += ` ${hours < 10 ? "0" : ""}${hours}:`
    } else if (hours <= 0) {
      duration += " 00:"
    }

    if (minutes > 0 || (duration !== "" && hours > 0) || (duration === "" && hours === 0)) {
      duration += `${minutes < 10 ? "0" : ""}${minutes}:`
    } else if (minutes <= 0) {
      duration += "00:"
    }

    if (
      remainingSeconds > 0 ||
      (duration !== "" && (hours > 0 || minutes > 0)) ||
      (duration === "" && hours === 0 && minutes === 0)
    ) {
      duration += `${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
    } else if (remainingSeconds <= 0) {
      duration += "00"
    }
  } else {
    if (days > 0) duration += `${days}d`
    if (seconds % 86400 >= 3600) duration += `${Math.floor((seconds % 86400) / 3600)}h`
    if (seconds % 3600 >= 60 || duration !== "") duration += `${Math.floor((seconds % 3600) / 60)}m`
    if (seconds % 60 > 0 || seconds / 60 < 1 || duration === "") duration += `${seconds % 60}s`
  }

  return duration.trim()
}

export const durationToSeconds = (duration) => {
  const now = Math.trunc(new Date().getTime() / 1000)
  duration = duration.replace("now", `${now}s`)
  let currentNumber = ""
  let seconds = 0
  let sign = 1
  for (const letter of duration) {
    if (!isNaN(parseInt(letter))) {
      currentNumber += letter
    } else {
      let number = parseInt(currentNumber)
      switch (letter) {
        case "d":
        case "h":
        case "m":
        case "s":
          switch (letter) {
            case "d":
              number *= 86400
              break
            case "h":
              number *= 3600
              break
            case "m":
              number *= 60
              break
          }
          seconds += sign * number
          break
        case "+":
          sign = 1
          break
        case "-":
          sign = -1
          break
        case " ":
          continue
        default:
          return NaN
      }
      currentNumber = ""
    }
  }

  return seconds
}
export const toColonDuration = (seconds) => {
  const hoursString = parseInt((seconds % 86400) / 3600)
    .toString()
    .padStart(2, "0")
  const minutesString = parseInt((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0")
  const secondsString = parseInt(seconds % 60)
    .toString()
    .padStart(2, "0")
  return `${hoursString}:${minutesString}:${secondsString}`
}
export const pxOrNot = (property, value) => {
  if (!value) return ""
  if (typeof value === "string" && (value.includes("%") || value.includes("px"))) {
    return `${property}: ${value}`
  }
  return `${property}: ${value}px`
}

/**
 * @param {Object} range
 * @param {number=} range.seconds
 * @param {Date} range.from
 * @param {Date} range.to
 * @returns {{from: number, to: number}}
 */
export const getUnixFromToByRange = (range) => {
  if (!range) return { from: 0, to: 0 }
  if (range.seconds) {
    return {
      from: Math.trunc(Date.now() / 1000 - range.seconds),
      to: Math.trunc(Date.now() / 1000),
    }
  }
  return {
    from: Math.trunc(range.from.getTime() / 1000),
    to: Math.trunc(range.to.getTime() / 1000),
  }
}
export const downloadStringAsFile = (filename, text) => {
  const element = document.createElement("a")
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text))
  element.setAttribute("download", filename)

  element.style.display = "none"
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
export const getInt = (value, defaultValue = 0) => {
  switch (typeof value) {
    case "number":
      return Math.trunc(value)
    case "string":
      return parseInt(value)
    default:
      return defaultValue
  }
}
export const getGetParameter = (name, defaultValue) => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  if (params[name] !== null) return params[name]
  return defaultValue
}
export const getDefaultRange = () => {
  return {
    from: new Date(new Date().getTime() - 86400000),
    to: new Date(),
    seconds: 86400,
  }
}
export const getMaxRange = () => {
  return {
    from: new Date(0),
    to: new Date(2147483647000),
  }
}
export const getDefaultRangeAndRefresh = () => {
  return {
    range: getDefaultRange(),
    refresh: 0,
  }
}
export const log = (message) => {
  this.$store.commit("log", message)
}
export const logWithId = (message, id) => {
  this.$store.commit("log", {
    id: id,
    message: message,
  })
}
export const generateUUID = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  )
}
export const prettyPrintJSON = (value) => {
  value = JSON.stringify(JSON.parse(value), null, 2)
  value = value.replace(/&/g, "&amp;").replace(/</g, "&lt;").replace(/>/g, "&gt;")
  return value.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g,
    function (match) {
      let cls = "darkorange"
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "red"
        } else {
          cls = "green"
        }
      } else if (/true|false/.test(match)) {
        cls = "blue"
      } else if (/null/.test(match)) {
        cls = "magenta"
      }
      return '<span style="color: ' + cls + ';">' + match + "</span>"
    }
  )
}
export const getIntURLSearchParam = (key, defaultValue) => {
  const params = new URLSearchParams(window.location.search)
  const value = params.get(key)
  if (value || !defaultValue) {
    if (value) return parseInt(value)
    return 0
  }
  return parseInt(defaultValue)
}
export const getURLSearchParam = (key, defaultValue) => {
  const params = new URLSearchParams(window.location.search)
  const value = params.get(key)
  if (value || !defaultValue) return value
  return defaultValue
}
export const setURLSearchParam = (key, value) => {
  const currentPath = window.location.href
  const regex = new RegExp(`[&?]${key}=[^&]+`)
  const matches = currentPath.match(regex)
  let newPath = currentPath
  if (matches) {
    newPath = newPath.replace(matches[0], `${matches[0].charAt(0)}${key}=${value}`)
  } else {
    if (!newPath.includes("?")) {
      newPath += "?"
    } else {
      newPath += "&"
    }

    if (value !== undefined) {
      newPath += `${key}=${value}`
    } else {
      newPath = ""
    }
  }
  history.replaceState(null, "", newPath)
}
export const removeURLSearchParam = (key) => {
  setURLSearchParam(key, undefined)
}
export const getValueByHeader = (item, header) => {
  if (!header.value) {
    console.error('Headers must have a "value" attribute.')
    return "ERROR"
  }

  let value
  if (!header.value.includes(".")) {
    value = item[header.value]
  } else {
    const parts = header.value.split(".")
    if (parts.length === 2) {
      value = item[parts[0]][parts[1]]
    } else if (parts.length === 3) {
      value = item[parts[0]][parts[1]][parts[2]]
    } else {
      console.error("Maximum value depth of 2 supported. Extend code to support more.")
    }
  }
  if (header.default && !value) value = header.default

  return value
}
export const getFormattedValueByHeader = (item, header) => {
  let value = getValueByHeader(item, header)
  if (header.formatter) value = header.formatter(value)
  return value
}
export const getValueByFunctionOrDirectly = (value, ...args) => {
  if (typeof value === "function") return value(...args)
  return value
}
export const convertTemplateToRegular = (template) => {
  let newValue
  if (template.type === "string") {
    newValue = template.content.toString()
  } else if (template.type === "number") {
    newValue = parseFloat(template.content)
  } else if (template.type === "boolean") {
    newValue = parseBoolean(template.content)
  } else if (template.type === "object") {
    newValue = {}
    for (const [key, value] of Object.entries(template.content)) {
      newValue[key] = convertTemplateToRegular(value)
    }
  } else if (template.type === "array") {
    newValue = []
    for (const value of template.content) {
      newValue.push(convertTemplateToRegular(value))
    }
  } else if (template.type === "null") {
    newValue = null
  }
  return newValue
}
export const clamp = (num, min, max) => {
  return Math.min(Math.max(num, min), max)
}
export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
export const setHistoryComment = (obj, comment) => {
  const result = { ...obj }
  if (comment)
    result.history = {
      ...result.history,
      comment: comment,
    }
  return result
}

export const parseUmlauts = (text) => {
  return text.replace(/ü/g, "&uuml;").replace(/ö/g, "&ouml;").replace(/ä/g, "&auml;")
}

export const containsFalse = (obj) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (!containsFalse(obj[key])) {
        return true
      }
    } else {
      if (obj[key] === false) {
        return true
      }
    }
  }
  return false
}

export const camelToSnake = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const camelToKebab = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
}

export const translatePhpRouteToVueRoute = (route) => {
  if (!route.query.f) {
    return route.fullPath
  }

  if (
    route.query.f === "testing" &&
    route.query.f2 === "testInfo" &&
    route.query.function === "getInfo"
  ) {
    return `/testing/test-info/${route.query.n_id}/${route.query.n_id_key}`
  }

  if (
      route.query.f === "testing" &&
      route.query.f2 === "testsGroup" &&
      route.query?.groupID
  ) {
    return `/testing/test-group/${route.query.groupID}`
  }

  let fallbackRoute = ""

  if (route.query.f) {
    fallbackRoute += `/${camelToKebab(route.query.f)}`
  }

  if (route.query.f === "global") {
    if (route.query.f2 === "resetPassword") {
      route.query.f2 = "reset-password"
    }
    fallbackRoute = `/${route.query.f2}`
  } else if (route.query.f2) {
    fallbackRoute += `/${camelToKebab(route.query.f2)}`
  }

  if (route.query.f3) {
    fallbackRoute += `/${camelToKebab(route.query.f3)}`
  }

  if (route.query.f4) {
    fallbackRoute += `/${camelToKebab(route.query.f4)}`
  }

  if (route.query.n_id) {
    fallbackRoute += `/${route.query.n_id}`
  }

  if (route.query.n_id_key) {
    fallbackRoute += `/${route.query.n_id_key}`
  }

  const remainingQuery = { ...route.query }
  delete remainingQuery.f
  delete remainingQuery.f2
  delete remainingQuery.f3
  delete remainingQuery.f4
  delete remainingQuery.n_id
  delete remainingQuery.n_id_key

  const query = new URLSearchParams(remainingQuery).toString()

  if (query) {
    fallbackRoute += `?${query}`
  }

  return fallbackRoute
}
