<script>
import { defineComponent, ref, watch } from 'vue';
import XDialog from '@/components/basic/XDialog.vue';
import XSelect from '@/components/basic/XSelect.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import userService from '@/js/services/UserService';
import { useSwitchContext } from "@/composition/user/use-switch-context"

export default defineComponent({
  name: 'ContextSwitcher',
  components: {LoadingDialog, XSelect, XDialog},
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup(_, {emit}) {
    const organizationsWithProjects = ref([]);
    const { doContextSwitch } = useSwitchContext()

    userService.getOrganizationsWithProjects((response) => {
      response.sort((a, b) => a.name.localeCompare(b.name));
      organizationsWithProjects.value = response;
    });

    const storedOrganizationString = localStorage.getItem('organization');
    const storedProjectString = localStorage.getItem('project');
    const storedLastProjectIdsString = localStorage.getItem('last-project-id');

    const storedOrganization = storedOrganizationString ? JSON.parse(storedOrganizationString).id : 0;
    const storedProject = storedProjectString ? JSON.parse(storedProjectString).id : 0;
    const storedLastProjectIds = storedLastProjectIdsString ? JSON.parse(storedLastProjectIdsString) : {};

    const organization = ref(storedOrganization);
    const project = ref(storedProject);

    const loading = ref(false);

    const switchContext = async () => {
      loading.value = true;

      try {
        await doContextSwitch({ projID: project.value, orgID: organization.value })
        storedLastProjectIds[organization.value] = project.value;
        localStorage.setItem('last-project-id', JSON.stringify(storedLastProjectIds));
        location.reload()
      } finally {
        emit('input', false);
        loading.value = false;
      }
    }

    const buttons = [
      {
        text: 'Switch',
        icon: 'mdi-move-resize-variant',
        color: 'save',
        textColor: 'white',
        click: switchContext,
        disabled: () => organizationsWithProjects.value.length === 0,
      },
    ];

    watch(organization, (value) => {
      if (value) {
        const projects = organizationsWithProjects.value.find(x => x.id === value).projects;
        if (storedLastProjectIds[value] && projects.find(x => x.id === storedLastProjectIds[value])) {
          project.value = parseInt(storedLastProjectIds[value]);
        } else {
          project.value = projects[0].id;
        }
      }
    });

    return {organizationsWithProjects, organization, project, loading, buttons};
  },
});
</script>

<template>
  <XDialog
      :value="value"
      title="Switch Project"
      :buttons="buttons"
      @input="$emit('input', $event)"
      content-class="context-switcher-dialog">
    <template #dialog-content>
      <div>
        <div class="context-switcher-dialog-content">
          <div class="context-switcher-text">
            <div>
              Degust follows an organizational and project structure.
            </div>

            <div>
              An organization can be a company you work for. Your first organization was set up when you registered. You
              can
              change the settings of this organization in
              <router-link
                :to="{ name: 'general-organization-settings' }"
                @click.native="() => $emit('input', false)"
              >
                this
              </router-link>
              menu.
            </div>
            <div>
              Projects are test suites set up for specific purposes, such as the next release of a service on your
              network.
            </div>
            <div>
              You can work for different organizations/companies and there on different projects each. You have to
              switch to
              these projects via this dialog.
            </div>
          </div>
          <XSelect
              label="Organization"
              v-model="organization"
              :items="organizationsWithProjects"
              item-value="id"
              item-text="name"
              :disabled="organizationsWithProjects.length === 0"
              required/>
          <XSelect
              label="Project"
              v-model="project"
              :items="organization && organizationsWithProjects.length > 0 ?
               organizationsWithProjects.find(x => x.id === organization).projects : []"
              item-value="id"
              item-text="name"
              :disabled="organizationsWithProjects.length === 0"
              required/>
        </div>
        <LoadingDialog v-model="loading"/>
      </div>
    </template>
  </XDialog>
</template>

<style>
.context-switcher-dialog {
  position: absolute;
  top: 0;
  right: 0;
}
</style>

<style scoped>
.context-switcher-dialog-content {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.context-switcher-text {
  display: flex;
  flex-direction: column;
  gap: 14px;
  font-size: 14px;
  color: #666666;
}

.orga-settings-link {
  cursor: pointer;
  color: var(--v-primary-base);
}
</style>
