<template>
  <v-app
    v-if="!publicContent"
    id="app-root"
    :key="currentHash"
    v-cloak
    :class="`${version >= 2 ? 'app-v2' : ''}`"
  >
    <DialogsRoot>
      <MenuBar/>
      <demoMessageCard
          v-if="$store.state?.project?.isDemoProject"
          :userInfo="$store.state.project.isDemoProject"
          :result="result"
      />
      <v-main class="main-v3" >
        <router-view />
      </v-main>

      <footerBlock :userInfo="userInfo" :result="result"></footerBlock>

      <div id="snackbar">
        <v-snackbar
            v-for="(notification, i) of $store.state.notifications"
            :key="notification.id"
            v-model="notification.show"
            :style="`margin-bottom: ${i * 60 + 30}px;`">
          {{ notification.text }}
        </v-snackbar>
      </div>

      <div class="refresh-container" v-if="hashChanged && $root.env !== 'development'">
        <div class="notification-header">
          <button type="button" class="close-refresh-modal" @click="closeModal" aria-label="Close">
            <span aria-hidden="true"><i class="fal fa-times fa-sm"></i></span>
          </button>
        </div>

        <div class="notification-body">
          <div class="notification-button">
            <p class="text-center font12">An update is available. Please save all current work and click update below.
              You
              can also accept these updates by refreshing your browswer at any time.</p>
            <p class="text-center">
              <span class="font10">Not updating may result in errors.</span>
            </p>
          </div>

          <div class="refresh-button text-center">
            <button class="btn btn-default" @click="reloadApp">Update</button>
          </div>
        </div>
      </div>
    </DialogsRoot>
  </v-app>

  <PublicContent v-else/>
</template>

<script>
import { defineComponent, provide, readonly, getCurrentInstance } from 'vue';
import footerBlock from '@/components/legacy/footerBlock.vue';
import PublicContent from '@/components/specific/PublicContent.vue';
import {refreshPageMixin} from '@/mixins/refresh-page';
import DialogsRoot from '@/components/specific/Dialogs/DialogsRoot.vue';
import MenuBar from '@/components/specific/menu/AppMenu.vue';
import { useReactiveAuthFlag, tokenRefresher, doMigration as doTokenMigration } from "@/auth-tools"
import { useRouter } from "vue-router/composables"
import { useBroadcastChannel } from "@/composition/web-api/use-broadcast-channel"
import { useSwitchContext } from "@/composition/user/use-switch-context"
import demoMessageCard from "@/components/dynamic/demoMessageCard.vue";

const IS_AUTH_PROVIDE = 'IS_AUTH';
const BROADCAST_CHANNEL_NAME = "listen-logout";
const ID = Math.random().toString(36).substr(2, 9);

export default defineComponent({
  components: {
    demoMessageCard,
    MenuBar,
    PublicContent,
    footerBlock,
    DialogsRoot,
  },

  mixins: [refreshPageMixin],

  setup() {
    const { isAuthenticated } = useReactiveAuthFlag()
    const router = useRouter()
    const vm = getCurrentInstance()
    const { subscribeToContextSwitch } = useSwitchContext()
    subscribeToContextSwitch(() => {
      location.reload()
    })
    const onLogout = () => {
      const store = vm.proxy.$store
      store.commit("resetState")
      router.push({ name: "login" })
    }
    const {
      send,
    } = useBroadcastChannel({
      name: BROADCAST_CHANNEL_NAME,
      onmessage: (event) => {
        const data = event.data
        if (data.isLogout && data.id !== ID) {
          onLogout()
        }
      }
    })

    tokenRefresher.subscribe(({ isAuth }) => {
      if (!isAuth) {
        onLogout()
        send({ isLogout: true, id: ID })
      }
    })

    const isAuth = readonly(isAuthenticated)
    provide(IS_AUTH_PROVIDE, {
      isAuth,
    })

    doTokenMigration(tokenRefresher)

    return {
      isAuth,
    }
  },

  data() {
    return {
      loading: false,
      post: null,
      error: null,
      content: [],
      result: null,
      menu: null,
      userInfo: null,
    };
  },

  mounted() {
    if (this.isAuth) {
      return this.$store.dispatch('fetchEssentialData');
    }
  },

  computed: {
    smallHeader() {
      return this.result != null && 'version' in this.result && this.version >= 2;
    },

    publicContent() {
      return this.$route.fullPath.startsWith('/charts');
    },

    version() {
      if (this.result) return this.result.version;
      return 1;
    },
  },
});
</script>

<style lang="scss">
@import "./assets/styles/style.css";

:root {
  --app-header-height: 88px;
  --app-footer-height: 25px;
  --app-main-height: calc(100vh - var(--app-header-height) - var(--app-footer-height));
}

html {
  overflow-y: auto;
}

[v-cloak] {
  display: none;
}

/**
  * To avoid double scrollbars on the page
  * can be implemented gradually for every page via --css-variables on the :root (SimsView.vue as an example)
  */
#app-root > .v-application--wrap {
  display: var(--v-application-wrap-display, flex);
  grid-template-rows: var(--app-header-height) var(--app-main-height) var(--app-footer-height);
  grid-template-columns: 100%;
  overflow: var(--v-application-wrap-overflow, initial);
}

.main-v3 {
  display: flex;
  flex: 1 0 auto;
  max-width: 100%;
  overflow-y: auto;
  /**
    * if #app-root > .v-application--wrap display: grid, then you don't need to set max-height for .main-v3
  */
  max-height: var(--main-v3-max-height, var(--app-main-height));
}

headerContent, footerBlock {
  flex-shrink: 0; /* don't shrink */
  flex-grow: 0; /* don't grow */
}

.refresh-container {
  width: 15%;
  position: fixed;
  bottom: 10px;
  right: 15px;
  background-color: white;
  padding: 25px;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.05);
}

.close-refresh-modal {
  position: absolute;
  right: 5px;
  top: 5px;
  border: none;
}

#snackbar {
  position: relative;
  z-index: 9999;
}
</style>
