const _onmessageerror = (event) => {
  console.error(`
BroadcastChannel Error
Data: ${event.data}
Origin: ${event.origin}
LastEventId: ${event.lastEventId}
Source: ${event.source}
Ports: ${event.ports}
`)
}

export const useBroadcastChannel = ({ name, onmessage, onmessagerror = _onmessageerror }) => {
  const bc = new BroadcastChannel(name)

  bc.addEventListener("message", onmessage)
  bc.addEventListener("messageerror", (event) => {
    onmessagerror(event)
  })

  const send = (data) => {
    bc.postMessage(data)
  }

  const close = () => {
    bc.close()
  }

  return {
    send,
    close,
  }
}
