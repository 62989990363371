<script>
import {computed, defineComponent, ref} from 'vue';
import {useStore} from '@/store';
import ConnectionsMenu from '@/components/specific/menu/ConnectionsMenu.vue';
import MenuLinkList from '@/components/specific/menu/MenuLinkList.vue';
import userService from '@/js/services/UserService';

export default defineComponent({
  name: 'ConfigurationMenu',

  components: {
    MenuLinkList,
    ConnectionsMenu,
  },

  setup() {
    const store = useStore();

    const activeTab = ref(0);

    const tabs = ['Outbound Connections', 'Inbound Connections', 'Objects'];
    if (!store.state.project.systemIds.length) {
      tabs.splice(1, 1);
    }

    const outboundConnectionsCategories = [
      {
        name: '2G/3G',
        availableSystems: [
          {
            text: '2G/3G_AAA',
            path: '/2g-3g/aaa',
          },
        ],
      },
      {
        name: '4G',
        availableSystems: [
          {
            text: 'AAA',
            path: '/4g/aaa',
          },
          {
            text: 'ABMF',
            path: '/4g/abmf',
          },
          {
            text: 'AF',
            path: '/4g/af',
          },
          {
            text: 'EIR',
            path: '/4g/eir',
          },
          {
            text: 'GMLC',
            path: '/4g/gmlc',
          },
          {
            text: 'HSS',
            path: '/4g/hss',
          },
          {
            text: 'MME',
            path: '/4g/mme',
          },
          {
            text: 'OCF',
            path: '/4g/ocf',
          },
          {
            text: 'OFCS',
            path: '/4g/ofcs',
          },
          {
            text: 'PCRF',
            path: '/4g/pcrf',
          },
          {
            text: 'PGW',
            path: '/4g/pgw',
          },
          {
            text: 'SGW',
            path: '/4g/sgw',
          },
          {
            text: 'SLF',
            path: '/4g/slf',
          },
          {
            text: 'SPR',
            path: '/4g/spr',
          },
          {
            text: 'ePDG',
            path: '/4g/epdg',
          },
        ],
      },
      {
        name: '5G',
        availableSystems: [
          {
            text: '5G-EIR',
            path: '/5g/eir',
          },
          {
            text: 'AF',
            path: '/5g/af',
          },
          {
            text: 'AMF',
            path: '/5g/amf',
          },
          {
            text: 'AUSF',
            path: '/5g/ausf',
          },
          {
            text: 'BSF',
            path: '/5g/bsf',
          },
          {
            text: 'CHF',
            path: '/5g/chf',
          },
          {
            text: 'DWDAF',
            path: '/5g/dwdaf',
          },
          {
            text: 'GMLC',
            path: '/5g/gmlc',
          },
          {
            text: 'HSS',
            path: '/5g/hss',
          },
          {
            text: 'LMF',
            path: '/5g/lmf',
          },
          {
            text: 'NEF',
            path: '/5g/nef',
          },
          {
            text: 'NRF',
            path: '/5g/nrf',
          },
          {
            text: 'NSSF',
            path: '/5g/nssf',
          },
          {
            text: 'OTAF',
            path: '/5g/otaf',
          },
          {
            text: 'PCF',
            path: '/5g/pcf',
          },
          {
            text: 'SCEF',
            path: '/5g/scef',
          },
          {
            text: 'SMF',
            path: '/5g/smf',
          },
          {
            text: 'SMSF',
            path: '/5g/smsf',
          },
          {
            text: 'UCMF',
            path: '/5g/ucmf',
          },
          {
            text: 'UDM',
            path: '/5g/udm',
          },
          {
            text: 'UDR',
            path: '/5g/udr',
          },
          {
            text: 'VAE',
            path: '/5g/vae',
          },
        ],
      },
      {
        name: 'Fixed Net',
        availableSystems: [
          {
            text: 'BRAS',
            path: '/fixed-net/bras',
          },
        ],
      },
      {
        name: 'Application',
        availableSystems: [
          {
            text: 'SSH',
            path: '/application/ssh',
          },
          {
            text: 'Selenium',
            path: '/application/selenium',
          },
        ],
      },
      {
        name: 'Protocols',
        availableSystems: [
          {
            text: 'Diameter',
            path: '/protocols/diameter',
          },
          {
            text: 'HTTP',
            path: '/protocols/http',
          },
          {
            text: 'LDAP',
            path: '/protocols/ldap',
          },
          {
            text: 'RADIUS',
            path: '/protocols/radius',
          },
        ],
      },
    ];

    const inboundConnectionsCategories = [
      {
        name: '2G/3G',
        availableSystems: [
          {
            text: '2G/3G_AAA',
            path: '/2g-3g/aaa',
          },
          {
            text: 'GGSN',
            path: '/2g-3g/ggsn',
          },
          {
            text: 'UR',
            path: '/2g-3g/ur',
          },
        ],
      },
      {
        name: '4G',
        availableSystems: [
          {
            text: 'AAA',
            path: '/4g/aaa',
          },
          {
            text: 'ABMF',
            path: '/4g/abmf',
          },
          {
            text: 'AF',
            path: '/4g/af',
          },
          {
            text: 'EIR',
            path: '/4g/eir',
          },
          {
            text: 'GMLC',
            path: '/4g/gmlc',
          },
          {
            text: 'HSS',
            path: '/4g/hss',
          },
          {
            text: 'MME',
            path: '/4g/mme',
          },
          {
            text: 'OCF',
            path: '/4g/ocf',
          },
          {
            text: 'OFCS',
            path: '/4g/ofcs',
          },
          {
            text: 'PCRF',
            path: '/4g/pcrf',
          },
          {
            text: 'PGW',
            path: '/4g/pgw',
          },
          {
            text: 'SGW',
            path: '/4g/sgw',
          },
          {
            text: 'SLF',
            path: '/4g/slf',
          },
          {
            text: 'SPR',
            path: '/4g/spr',
          },
          {
            text: 'ePDG',
            path: '/4g/epdg',
          },
        ],
      },
      {
        name: '5G',
        availableSystems: [
          {
            text: '5G-EIR',
            path: '/5g/eir',
          },
          {
            text: 'AF',
            path: '/5g/af',
          },
          {
            text: 'AMF',
            path: '/5g/amf',
          },
          {
            text: 'AUSF',
            path: '/5g/ausf',
          },
          {
            text: 'BSF',
            path: '/5g/bsf',
          },
          {
            text: 'CHF',
            path: '/5g/chf',
          },
          {
            text: 'DWDAF',
            path: '/5g/dwdaf',
          },
          {
            text: 'GMLC',
            path: '/5g/gmlc',
          },
          {
            text: 'HSS',
            path: '/5g/hss',
          },
          {
            text: 'LMF',
            path: '/5g/lmf',
          },
          {
            text: 'NEF',
            path: '/5g/nef',
          },
          {
            text: 'NRF',
            path: '/5g/nrf',
          },
          {
            text: 'NSSF',
            path: '/5g/nssf',
          },
          {
            text: 'OTAF',
            path: '/5g/otaf',
          },
          {
            text: 'PCF',
            path: '/5g/pcf',
          },
          {
            text: 'SCEF',
            path: '/5g/scef',
          },
          {
            text: 'SMF',
            path: '/5g/smf',
          },
          {
            text: 'SMSF',
            path: '/5g/smsf',
          },
          {
            text: 'UCMF',
            path: '/5g/ucmf',
          },
          {
            text: 'UDM',
            path: '/5g/udm',
          },
          {
            text: 'UDR',
            path: '/5g/udr',
          },
          {
            text: 'VAE',
            path: '/5g/vae',
          },
        ],
      },
      {
        name: 'Fixed Net',
        availableSystems: [
          {
            text: 'BRAS',
            path: '/fixed-net/bras',
          },
        ],
      },
      {
        name: 'Protocols',
        availableSystems: [
          {
            text: 'Diameter',
            path: '/protocols/diameter',
          },
          {
            text: 'HTTP',
            path: '/protocols/http',
          },
          {
            text: 'LDAP',
            path: '/protocols/ldap',
          },
          {
            text: 'RADIUS',
            path: '/protocols/radius',
          },
        ],
      },
    ];

    const computedOutboundConnectionsCategories = computed(() => {
      const availableCategories = [];
      for (const category of outboundConnectionsCategories) {
        const availableSystems = [];
        for (const system of category.availableSystems) {
          const feature = store.state.menu[`/configuration/outbound-connections${system.path}`];
          if (feature && feature.status === 'active') {
            availableSystems.push(system);
          }
        }
        if (availableSystems.length) {
          availableCategories.push({
            name: category.name,
            availableSystems,
          });
        }
      }
      return availableCategories;
    })

    const computedInboundConnectionsCategories = computed(() => {
      const availableCategories = [];
      for (const category of inboundConnectionsCategories) {
        const availableSystems = [];
        for (const system of category.availableSystems) {
          const feature = store.state.menu[`/configuration/inbound-connections${system.path}`];
          if (feature && feature.status === 'active') {
            availableSystems.push(system);
          }
        }
        if (availableSystems.length) {
          availableCategories.push({
            name: category.name,
            availableSystems,
          });
        }
      }
      return availableCategories;
    })

    let objectsLinks = [
      {
        text: 'APN / B Numbers',
        path: '/apn-b-numbers',
      },
      {
        text: 'Devices',
        path: '/devices',
      },
      {
        text: 'Evaluation Parameters',
        path: '/evaluation-parameters',
      },
      {
        text: 'HSS Objects',
        path: '/hss-objects',
      },
      {
        text: 'LDAP Content',
        path: '/ldap-content',
      },
      {
        text: 'SGW',
        path: '/sgw',
      },
      {
        text: 'Subscriber Mobile',
        path: '/subscriber-mobile',
      },
      {
        text: 'Subscriber SIP',
        path: '/subscriber-sip',
      },
    ];

    const dictionaryLinks = [
      {
        text: 'RADIUS Attributes',
        path: '/radius-attributes',
      },
      {
        text: 'Diameter Attributes',
        path: '/diameter-attributes',
      },
    ];

    if (store.state.permissions.projectRole >= 2) {
      objectsLinks = objectsLinks.filter(link => !['/hss-objects', '/ldap-content'].includes(link.path));
    }
    if (store.state.permissions.projectRole >= 3) {
      const subscriberSipIndex = objectsLinks.findIndex(link => link.path === '/subscriber-sip');
      objectsLinks.splice(subscriberSipIndex, 1);
    }

    const recentlyUsedMenuItems = ref({outboundConnections: {}, inboundConnections: {}});
    userService.getRecentlyUsedMenuItems((response) => {
      recentlyUsedMenuItems.value = response;
    });

    const handleConnectionsMenuClick = (direction, category, system) => {
      userService.patchRecentlyUsedMenuItems({
        [direction]: {
          [category]: {
            [system.text]: 1,
          },
        },
      });
    };

    return {
      activeTab,
      tabs,
      computedOutboundConnectionsCategories,
      computedInboundConnectionsCategories,
      objectsLinks,
      dictionaryLinks,
      recentlyUsedMenuItems,
      handleConnectionsMenuClick,
    };
  },
});
</script>

<template>
  <div class="configuration-menu">
    <v-tabs v-model="activeTab" right>
      <v-tab v-for="(tab, i) of tabs" :key="i" @mouseover="activeTab = i">
        {{ tab }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="activeTab" class="configuration-menu-tabs-items">
      <v-tab-item>
        <ConnectionsMenu
            v-if="Object.keys(computedOutboundConnectionsCategories).length"
            base-path="/configuration/outbound-connections"
            :categories="computedOutboundConnectionsCategories"
            :recently-used="recentlyUsedMenuItems.outboundConnections"
            @navigation-click="(category, system) => handleConnectionsMenuClick('outboundConnections', category, system)"/>
      </v-tab-item>

      <v-tab-item v-if="$store.state.project.systemIds.length && Object.keys(computedInboundConnectionsCategories).length">
        <ConnectionsMenu
            base-path="/configuration/inbound-connections"
            :categories="computedInboundConnectionsCategories"
            :recently-used="recentlyUsedMenuItems.inboundConnections"
            @navigation-click="(category, system) => handleConnectionsMenuClick('inboundConnections', category, system)"/>
      </v-tab-item>

      <v-tab-item>
        <div class="objects-menu">
          <MenuLinkList
            title="Objects"
            base-path="/configuration/objects"
            :links="objectsLinks"
          />

          <MenuLinkList
            title="Dictionary"
            base-path="/configuration/dictionary"
            :links="dictionaryLinks"
          />
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style scoped>
.configuration-menu {
  width: 1000px;
}

.objects-menu {
  display: flex;
  gap: 20px;
  padding: 0 30px 15px 30px;
}
</style>
