import Service from '@/js/service.js';

const service = new Service(`${process.env.VUE_APP_USER_SERVICE}/user-service`);

const userService = {
    getUserData() {
        return new Promise((resolve, reject) => {
            service.getRequest(2, 'user-data', null, resolve, reject);
        });
    },
    getProject(then, error) {
        service.getRequest(1, 'projects/project', null, then, error);
    },
    getProjects(then, error) {
        service.getRequest(1, 'projects/projects', null, then, error);
    },
    getProjectHash(then, error) {
        service.getRequest(1, 'projects/project-hash', null, then, error);
    },
    getProjectNames(then, error) {
        service.getRequest(1, 'projects/names', null, then, error);
    },
    getProjectNamesHash(then, error) {
        service.getRequest(1, 'projects/names-hash', null, then, error);
    },
    getProjectNamesOwn(then, error) {
        service.getRequest(1, 'projects/names/own', null, then, error);
    },
    getProjectNamesHashOwn(then, error) {
        service.getRequest(1, 'projects/names-hash/own', null, then, error);
    },
    getProjectNamesByOrganization(then, error) {
        service.getRequest(1, 'projects/names/organization', null, then, error);
    },
    getProjectNamesHashByOrganization(then, error) {
        service.getRequest(1, 'projects/names-hash/organization', null, then, error);
    },
    getOrganizationManagers(then, error) {
        service.getRequest(1, 'organization-managers', null, then, error);
    },
    getOrganizationsWithProjects(then, error) {
        service.getRequest(1, 'organizations/with-projects', null, then, error);
    },
    getRecentlyUsedMenuItems(then, error) {
        service.getRequest(1, 'recently-used-menu-items', null, then, error);
    },
    patchRecentlyUsedMenuItems(menuItem, then, error) {
        service.patchRequest(1, 'recently-used-menu-items', menuItem, then, error);
    },
    getSystemList(then, error) {
        service.getRequest(1, 'system-list', null, then, error);
    }
};

export default userService;
