<script>
import {defineComponent} from 'vue';
import MenuLinkList from '@/components/specific/menu/MenuLinkList.vue';

export default defineComponent({
  name: 'SuperAdminMenu',
  components: {
    MenuLinkList,
  },
  setup() {
    const simManagementLinks = [
      {
        text: 'SIM Server',
        path: '/sim-server',
      },
      {
        text: 'SIM Slots',
        path: '/sim-slots',
      },
      {
        text: 'SIM Card Infos',
        path: '/sim-card-infos',
      },
      {
        text: 'SIM to Project Assignment',
        path: '/sim-to-project-assignment',
      },
    ];
    const clientManagementLinks = [
      {
        text: 'Client Locations',
        path: '/client-locations',
      },
      {
        text: 'Client Connection History',
        path: '/client-connection-history',
      },
      {
        text: 'Clients (Performance)',
        path: '/clients-performance',
      },
      {
        text: 'Modems Usage',
        path: '/modems-usage',
      },
      {
        text: 'Network Quality Measures',
        path: '/network-quality-measures',
      },
    ];
    const superAdminLinks = [
      {
        text: 'Active Probes',
        path: '/active-probes',
      },
      {
        text: 'Configure Emails',
        path: '/configure-emails',
      },
      {
        text: 'Email Server Config',
        path: '/email-server-config',
      },
      {
        text: 'Help Text',
        path: '/help-text',
      },
      {
        text: 'Log View',
        path: '/log-view',
      },
      {
        text: 'Testcase Search/Details',
        path: '/testcase-search-details',
      },
      {
        text: 'Organization',
        path: '/organization',
      },
      {
        text: 'Running Subscriber',
        path: '/running-subscriber',
      },
      {
        text: 'Switch Users',
        path: '/switch-users',
      },
      {
        text: 'Super Admin Panel',
        path: '/super-admin-panel',
      },
      {
        text: 'Service Log',
        path: '/service-log',
      },
      {
        text: 'All Running Tests',
        path: '/all-running-tests',
      },
    ];

    return {
      simManagementLinks,
      clientManagementLinks,
      superAdminLinks,
    };
  },
});

</script>

<template>
  <div class="super-admin-menu">
    <div class="left-side">
      <MenuLinkList
          title="SIM Management"
          base-path="/super-admin"
          :links="simManagementLinks"
      />
      <MenuLinkList
          title="Client Management"
          base-path="/super-admin"
          :links="clientManagementLinks"
      />
    </div>
    <div class="right-side">
      <MenuLinkList
        title="Super Admin"
        base-path="/super-admin"
        :links="superAdminLinks"
      />
    </div>
  </div>
</template>

<style scoped>
.super-admin-menu {
  display: flex;
  gap: 50px;
  padding: 20px 30px;
}

.left-side {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
