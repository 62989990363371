import { simService } from "@/api";

/* type CustomField = { id: string, name: string }
 */

const _initialState = {
  tags: [],
  customFields: [],
  lastTagQuery: "",
  wallets: [],
};

const sims = {
  namespaced: true,

  state: () => ({
    ..._initialState,
  }),

  getters: {
    sortedTags: (state) => state.tags.sort(),
    customFields: (state) => state.customFields,
    lastTagQuery: (state) => state.lastTagQuery,
    wallets: (state) => state.wallets,
  },

  mutations: {
    setTags(state, tags) {
      state.tags = tags;
    },

    addTags(state, tags) {
      state.tags = Array.from(new Set([...state.tags, ...tags]));
    },

    setWallets(state, wallets) {
      state.wallets = wallets;
    },

    setCustomFields(state, customFields) {
      state.customFields = customFields;
    },

    updateCustomFieldInList(state, { id, name }) {
      const index = state.customFields.findIndex((field) => field.id === id);

      if (index < 0) {
        return;
      }

      // eslint-disable-next-line no-undef
      const copy = structuredClone(state.customFields[index]);
      copy.name = name;
      state.customFields.splice(index, 1, copy);
    },

    removeCustomFieldFromList(state, id) {
      const index = state.customFields.findIndex((field) => field.id === id);
      if (index < 0) {
        return;
      }
      state.customFields.splice(index, 1);
    },

    updateCustomFieldsList(state, customField) {
      state.customFields.push(customField);
    },

    saveLastTagQuery(state, tagQuery) {
      state.lastTagQuery = tagQuery;
    },

    resetState(state) {
      Object.assign(state, _initialState);
    },
  },

  actions: {
    // this method needs to be called only to sync server data with the client
    // e.g. when the user delete last tag usage
    async fetchActualOrgTagsForSims({ commit }) {
      try {
        const tags = await simService.v2.getOrgTagsForSims();
        // replace the tags with the new ones
        commit("setTags", tags);
      } catch (e) {
        const text = "The tags are not fetched. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    },

    // this method refreshes the tags list and preserve the existing ones
    // e.g. the user creates a new tag on the fly
    async fetchOrgTagsForSims({ commit, state }, { isRefresh = false } = {}) {
      if (state.tags.length && !isRefresh) {
        return;
      }

      try {
        const tags = await simService.v2.getOrgTagsForSims();
        // add the new tags to the existing ones
        commit("addTags", tags);
      } catch (e) {
        const text = "The tags are not fetched. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    },

    async fetchOrgCustomFields({ commit, state }, { isRefresh = false } = {}) {
      if (state.customFields.length && !isRefresh) {
        return;
      }

      try {
        const customFields = await simService.v2.getCustomFields();
        commit("setCustomFields", customFields);
      } catch (e) {
        const text = "The custom fields are not fetched. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    },

    async createCustomField({ commit }, { name }) {
      try {
        const { id } = await simService.v2.addCustomField({ name });
        const newCustomField = { id, name };
        commit("updateCustomFieldsList", newCustomField);
        return id;
      } catch (e) {
        const text = "The field is not created. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    },

    async patchCustomField({ commit }, { id, name }) {
      try {
        await simService.v2.updateCustomField({ id, name });
        commit("updateCustomFieldInList", { id, name });
      } catch (e) {
        const text = "The field is not updated. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    },

    async deleteCustomField({ commit }, { id }) {
      try {
        await simService.v2.deleteCustomField(id);
        commit("removeCustomFieldFromList", id);
      } catch (e) {
        const text = "The field is not deleted. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    },

    async fetchWalletsList({ commit, state }, { isRefresh }) {
      if (state.wallets.length && !isRefresh) {
        return;
      }

      try {
        const wallets = await simService.v2.getWallets();
        commit("setWallets", wallets);
      } catch (e) {
        const text = "The wallets aren't fetched. There is unpredictable error occurred!";
        commit("notification", { text }, { root: true });
        throw e;
      }
    }
  },
};

export default sims;
