<script>
import { defineComponent, ref, computed, toRefs } from 'vue';
import MenuLinkList from '@/components/specific/menu/MenuLinkList.vue';
import XTextField from '@/components/basic/XTextField.vue';

export default defineComponent({
  name: 'ConnectionsMenu',
  components: {
    XTextField,
    MenuLinkList,
  },
  props: {
    basePath: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    recentlyUsed: {
      type: Object,
      required: true,
    },
  },

  emits: ["navigation-click"],

  setup(props) {
    const activeTab = ref(0);
    const search = ref("");
    const {
      categories,
      recentlyUsed,
    } = toRefs(props);

    const recentlyUsedSystems = computed(() => {
      const _t = activeTab.value;
      const _systems = categories.value?.[_t]?.availableSystems;
      const _recUsed = recentlyUsed.value[categories.value[_t].name];
      return _systems?.filter(s => {
        return _recUsed && _recUsed[s.text] > Math.trunc(Date.now() / 1000) - 7 * 24 * 60 * 60;
      }) || []
    })

    const availableSystems = computed(() => {
      const _t = activeTab.value;
      const _systems = categories.value?.[_t]?.availableSystems;
      return _systems?.filter((s) => s.text.toLowerCase().includes(search.value.toLowerCase())) || []
    })

    return {
      activeTab,
      search,
      recentlyUsedSystems,
      availableSystems,
    };
  },
});
</script>

<template>
  <div>
    <v-divider/>
    <div class="connections-menu-sections">
      <div class="categories">
        <div class="connections-menu-sections-title category-title">
          Category
        </div>

        <v-tabs v-model="activeTab" vertical>
          <v-tab v-for="(category, i) in categories" :key="i" @mouseover="activeTab = i">
            {{ category.name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-divider vertical/>

      <MenuLinkList
        title="Recently Used"
        :base-path="basePath"
        :links="recentlyUsedSystems"
        ignore-feature-store
        class="recently-used"
        @navigation-click="(link) => $emit('navigation-click', categories[activeTab].name, link)"
      />

      <v-divider vertical/>

      <div class="available-systems">
        <div class="connections-menu-sections-title">Available Systems</div>

        <XTextField
          v-model="search"
          placeholder="Search"
          append-icon="mdi-magnify"
          class="available-systems-search"
          @click.native.stop
        />

        <div class="available-systems-items">
          <router-link
            v-for="(system, i) of availableSystems"
            :key="i"
            class="available-system"
            :to="`${basePath}${system.path}`"
            @click.native="() => $emit('navigation-click', categories[activeTab].name, system)"
          >
            {{ system.text }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.connections-menu-sections {
  display: flex;
  width: 100%;
}

.categories {
  margin-right: 1px;
}

.category-title,
.recently-used,
.available-systems {
  padding: 10px 15px;
}

.available-systems {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.connections-menu-sections-title {
  color: var(--v-primary-base);
  letter-spacing: 0.08rem;
}

.available-systems-search {
  width: 100%;
}

.available-systems-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 237px;
  gap: 20px;
  padding: 10px 15px;
}

.available-system {
  color: var(--v-primary-base);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
</style>
