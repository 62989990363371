<script>
import {computed, defineComponent} from 'vue';
import {useStore} from '@/store';

export default defineComponent({
  name: 'MenuLinkList',

  props: {
    title: String,
    basePath: String,
    links: Array,
    ignoreFeatureStore: Boolean,
  },

  emits: ['navigation-click'],

  setup(props) {
    const store = useStore();

    const computedLinks = computed(() => {
      const availableLinks = [];
      for (const link of props.links.filter(x => !!x.path || x.type === 'divider')) {
        if (link.type === 'divider' || props.ignoreFeatureStore || store.state.permissions.superAdmin) {
          availableLinks.push(link);
        } else {
          const feature = store.state.menu[props.basePath + link.path];
          if (feature && feature.status === 'active') {
            availableLinks.push(link);
          }
        }
      }
      return availableLinks;
    })

    return {computedLinks};
  },
});
</script>

<template>
  <div class="menu-link-list">
    <div class="menu-link-list-title">
      {{ title }}
    </div>

    <div>
      <div v-for="(link, i) in computedLinks" :key="i">
        <template v-if="link.type !== 'divider'">
          <router-link
            v-if="!link.path.startsWith('http')"
            :to="`${basePath}${link.path}`"
            class="menu-link-list-link"
            @click.native="() => $emit('navigation-click', link)"
          >
            {{ link.text }}
          </router-link>

          <a
            v-else
            class="menu-link-list-link"
            :href="link.path"
            target="_blank"
          >
            {{ link.text }}
          </a>
        </template>

        <template v-else>
          <v-divider class="divider"/>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped>
.menu-link-list {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: var(--v-primary-base);
}

.menu-link-list-title {
  letter-spacing: 0.08rem;
}

.menu-link-list-link {
  font-size: 13px;
  cursor: pointer;
}

.menu-link-list-link:hover:before {
  color: #2b5593;
}

.menu-link-list-link:before {
  content: "\2022";
  position: relative;
  left: unset;
  padding-right: 3px;
  color: #9e9e9e6b;
  font-size: 28px;
  vertical-align: middle;
}

.divider {
  margin: 10px 0;
}
</style>
