<script>
import {defineComponent} from 'vue';
import MenuLinkList from '@/components/specific/menu/MenuLinkList.vue';
import {useStore} from '@/store';

export default defineComponent({
  name: 'AdministrationMenu',
  components: {MenuLinkList},
  setup() {
    const store = useStore();

    const myAccountLink = {
      text: 'My Account',
      path: '/my-account',
    };

    const allUsersLinks = [
      myAccountLink,
      {
        text: 'My Projects',
        path: '/my-projects',
      },
    ];

    let allProjectsLinks = [
      {
        text: 'Connectors',
        path: '/connectors',
      },
      {
        text: 'API Documentation',
        path: '/api-documentation',
      },
      {
        text: 'Spreadsheets',
        path: '/spreadsheets',
      },
    ];


    const generalSettingsLink = {
      text: 'General Settings',
      path: '/general-settings',
    };
    const usersLink = {
      text: 'Users',
      path: '/user',
    };
    const projectsLink = {
      text: 'Projects',
      path: '/project',
    };
    const permissionGroupsLink = {
      text: 'Permission Groups',
      path: '/permission-groups',
    };
    const activeProbesLink = {
      text: 'Active Probes',
      path: '/active-probes',
    };
    const chartTemplatesLink = {
      text: 'Chart Templates',
      path: '/chart-templates',
    };
    const reportTemplatesLink = {
      text: 'Report Templates',
      path: '/report-templates',
    };
    const explorersLink = {
      text: 'Explorers',
      path: '/explorers',
    };

    let allOrganizationLinks = [
      generalSettingsLink,
      usersLink,
      projectsLink,
      permissionGroupsLink,
      activeProbesLink,
      chartTemplatesLink,
      reportTemplatesLink,
      explorersLink,
      {
        text: 'Device Pools',
        path: '/device-pools',
      },
      {
        text: 'MSISDNs',
        path: '/msisdns',
      },
      {
        text: 'SIMs',
        path: '/sims',
      },
      {
        text: 'Go to Vue 3',
        path: process.env.VUE_APP_VUE3_CLIENT,
      }
    ];

    let usersLinks = [];
    let projectsLinks = [];
    let organizationLinks = [];

    if (!store.state.permissions.superAdmin) {
      if ([4, 5].includes(store.state.permissions.orgaRole)) {
        usersLinks = [
          myAccountLink,
        ];
        organizationLinks = [
          explorersLink,
        ];
      } else if ([3].includes(store.state.permissions.orgaRole)) {
        usersLinks = allUsersLinks;
        organizationLinks = [
          explorersLink,
        ];
      } else if ([2].includes(store.state.permissions.orgaRole)) {
        usersLinks = allUsersLinks;
        projectsLinks = allProjectsLinks;
        organizationLinks = [
          permissionGroupsLink,
          chartTemplatesLink,
          reportTemplatesLink,
          explorersLink,
        ];
      } else if ([1].includes(store.state.permissions.orgaRole)) {
        usersLinks = allUsersLinks;
        projectsLinks = allProjectsLinks;
        organizationLinks = [
          generalSettingsLink,
          usersLink,
          projectsLink,
          permissionGroupsLink,
          activeProbesLink,
          chartTemplatesLink,
          reportTemplatesLink,
          explorersLink,
        ];
      }
    } else {
      usersLinks = allUsersLinks;
      projectsLinks = allProjectsLinks;
      organizationLinks = allOrganizationLinks;
    }

    return {
      usersLinks,
      projectsLinks,
      organizationLinks,
    };
  },
});
</script>

<template>
  <div class="administration-menu">
    <MenuLinkList title="User" base-path="/administration/user" :links="usersLinks"/>
    <div class="right-side">
      <MenuLinkList
        v-if="projectsLinks.length"
        title="Project"
        base-path="/administration/project"
        :links="projectsLinks"
      />

      <v-divider v-if="projectsLinks.length && organizationLinks.length" class="divider"/>

      <MenuLinkList
        title="Organization"
        base-path="/administration/organization"
        :links="organizationLinks"
      />
    </div>
  </div>
</template>

<style scoped>
.administration-menu {
  display: flex;
  width: 350px;
}

.administration-menu > * {
  flex-basis: 50%;
}

.divider {
  margin: 15px 0 25px 0;
}
</style>
