const readFromStorage = (savedState, key) => {
  const data = localStorage.getItem(key)
  if (!data) return savedState

  try {
    savedState[key] = JSON.parse(data)
  } catch (err) {
    if (err instanceof SyntaxError) {
      savedState[key] = data
    }

    throw err
  }

  return savedState
}

const writeToStorage = (key, data) => {
  if (data == null) return

  if (typeof data === "string") {
    localStorage.setItem(key, data)
    return
  }

  localStorage.setItem(key, JSON.stringify(data))
}

const clearStorage = (key) => {
  localStorage.removeItem(key)
}

export const createPersistentStatePlugin = ({ reducer, filter = [], resetMutationName }) => {
  const keys = Array.from(reducer.keys())
  const savedState = keys.reduce(readFromStorage, {})

  return (store) => {
    if (Object.keys(savedState).length) {
      store.replaceState(Object.assign({}, store.state, savedState))
    }

    store.subscribe((mutation, state) => {
      if (mutation.type === resetMutationName) {
        for (const key of keys) {
          clearStorage(key)
        }

        return
      }

      if (filter.length && !filter.includes(mutation.type)) return

      for (const key of keys) {
        const data = reducer.get(key)(state)
        writeToStorage(key, data)
      }
    })
  }
}
