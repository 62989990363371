<template>
  <v-menu v-model="menu" offset-y :close-on-content-click="false">
    <template #activator="{}">
      <XTextField :value="selectText" label="Time range" @click="menu = true" readonly class="range-text-field">
        <template #append>
          <v-icon @click="menu = true" :class="`range-text-field-icon ${menu ? ' rotated-icon' : ''}`">
            mdi-menu-down
          </v-icon>
        </template>
      </XTextField>
    </template>

    <v-card class="menu-padding">
      <div class="menu-content">
        <div class="menu-content-left-side">
          <v-date-picker
            v-model="pickerDates"
            range
            :min="min ? dateToIsoDateString(min) : undefined"
            :max="max ? dateToIsoDateString(max) : undefined"
            @change="handlePickerInput"
          />

          <v-form v-model="valid" class="from-to-text-fields">
            <DateTimeRangeTextField 
              v-model="range"
              @input="custom = true"
            />
          </v-form>

        </div>
        <v-list outlined dense>
          <v-subheader class="predefined-time-ranges-headline">
            Predefined Time Ranges
          </v-subheader>

          <v-list-item-group
            v-model="predefinedRange"
            @change="setRange"
          >
            <v-list-item
                v-for="(predefinedRangesItem, i) of PREDEFINED_RANGES" :key="i" class="predefined-time-range">
              {{ predefinedRangesItem.text }}
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>
      <v-divider/>

      <div class="menu-buttons">
        <XBtn text="OK" color="primary" @click="() => emitInput()" :disabled="!valid"/>

        <XBtn text="Cancel" color="secondary" @click="menu = false"/>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { defineComponent, ref } from "vue"
import DateTimeRangeTextField from '@/components/extended/DateTimeRangeTextField.vue';
import XTextField from '@/components/basic/XTextField.vue';
import XBtn from '@/components/basic/XBtn.vue';
import {dateToDateTimeString, dateToIsoDateString, deepCopy, isoDateStringToDate} from '@/js/general';

const PREDEFINED_RANGES = [
  {
    value: 300,
    text: 'Last 5 min',
  },
  {
    value: 900,
    text: 'Last 15 min',
  },
  {
    value: 1800,
    text: 'Last 30 min',
  },
  {
    value: 3600,
    text: 'Last 1 hour',
  },
  {
    value: 21600,
    text: 'Last 6 hours',
  },
  {
    value: 43200,
    text: 'Last 12 hours',
  },
  {
    value: 86400,
    text: 'Last 24 hours',
  },
  {
    value: 172800,
    text: 'Last 2 days',
  },
  {
    value: 604800,
    text: 'Last 7 days',
  },
  {
    value: 1209600,
    text: 'Last 14 days',
  },
  {
    value: 2592000,
    text: 'Last 30 days',
  },
]

export default defineComponent({
  name: 'DateTimeRange',

  components: {
    DateTimeRangeTextField,
    XTextField,
    XBtn,
  },

  props: {
    value: Object,
    min: Date,
    max: Date,
    defaultSeconds: {
      type: Number,
      default: 86400,
    },
  },

  setup(props) {
    const DEFAULT_RANGE = {
      from: new Date(new Date().getTime() - props.defaultSeconds * 1000),
      to: new Date(),
      seconds: props.defaultSeconds,
    }

    // eslint-disable-next-line no-undef
    const range = ref(props.value ? structuredClone(props.value) : DEFAULT_RANGE)

    const determinePredefinedRange = () => {
      const r = PREDEFINED_RANGES.findIndex(_r => _r.value === range.value.seconds)
      if (r >= 0) {
        return r
      }
      return PREDEFINED_RANGES.findIndex(_r => _r.value === props.defaultSeconds)
    }

    const predefinedRange = ref(determinePredefinedRange())
    const pickerDates = ref([
        dateToIsoDateString(range.value.from),
        dateToIsoDateString(range.value.to),
    ])

    return {
      range,
      predefinedRange,
      pickerDates,
      PREDEFINED_RANGES,
    }
  },

  data() {
    return {
      menu: false,
      valid: false,
      custom: false,
    };
  },

  computed: {
    selectText() {
      let selectText = '';
      if (typeof this.predefinedRange !== 'undefined') {
        selectText = this.custom ?
            `${dateToDateTimeString(this.range.from)}  -  ${dateToDateTimeString(this.range.to)}` :
            PREDEFINED_RANGES[this.predefinedRange].text;
      }
      return selectText;
    },
  },

  methods: {
    dateToIsoDateString,

    emitInput() {
      this.$emit('input', deepCopy(this.range));
      this.menu = false;
    },

    handlePickerInput(value) {
      this.range.from = isoDateStringToDate(value[0]);
      const to = isoDateStringToDate(value[1]);
      to.setHours(23);
      to.setMinutes(59);
      to.setSeconds(59);
      to.setMilliseconds(999);
      this.range.to = to;
      this.range.seconds = 0;
      this.custom = true;
    },

    /**
    * @param {number} index
    */
    setRange(index) {
      if (typeof index === "undefined") return

      const range = PREDEFINED_RANGES[index];
      const now = new Date();
      this.range.from = new Date(now.getTime() - range.value * 1000);
      this.range.to = now;
      this.range.seconds = range.value;
      this.custom = false;
    },
  },
});
</script>

<style scoped>
.range-text-field {
  width: 321px;
}

.range-text-field,
.range-text-field >>> .v-text-field > .v-input__control > .v-input__slot,
.range-text-field >>> input {
  cursor: pointer;
}

.range-text-field-icon {
  transition: .3s cubic-bezier(.25, .8, .5, 1), visibility 0s;
}

.range-text-field-icon.rotated-icon {
  transform: rotate(180deg);
}

.menu-content {
  display: flex;
  gap: 16px;
  padding: 16px;
}

.menu-content-left-side {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.from-to-text-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.predefined-time-ranges-headline {
  color: var(--v-text-base);
  font-weight: bold;
}

.predefined-time-range {
  font-size: 13px;
  font-weight: 500;
}

.menu-buttons {
  display: flex;
  gap: 10px;
  padding: 16px;
}

.menu-content >>> .v-picker__title {
  display: none;
}
</style>
